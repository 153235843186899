import React from 'react';
import Layout from "../../layout/Layout";
import SEO from "../../components/seo";

import TemplateLegalPage from '../../blocks/templateLegalPage';

import Rectangle from "../../components/Rectangle";

import enMessages from '../../i18n/legal/requisite/en.js'
import ruMessages from '../../i18n/legal/requisite/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const RequisitePage = ({ pathContext: { locale } }) => {
    const section = 'requisite';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            <div>
                {messages[locale].content.rectangle.map((item, index) => {
                    return <Rectangle
                        key={`rectangle_${index}`}
                        title={item.title}
                        description={item.description}
                    />
                })}
                <div
                    className="LegalInformation-requisite-description"
                    dangerouslySetInnerHTML={{ __html: messages[locale].content.description }}
                />
            </div>

        </TemplateLegalPage>
    )
};

export default RequisitePage;
